import { isString } from './objects';
export function get(object, path, defaultValue) {
    if (!path || !isString(path)) {
        return defaultValue;
    }
    const parts = path.split('.');
    for (const part of parts) {
        if (!object)
            return defaultValue;
        object = object[part];
    }
    return object ?? defaultValue;
}
