/* eslint-env browser */
/* eslint no-console: 0 */

function readCookie(key) {
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(key).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`),
        '$1',
      ),
    ) || false
  );
}

export default function (msg) {
  const debugMode = readCookie('_pulse2debug');
  if (debugMode) {
    console.log('pulse4', msg);
  }
}
