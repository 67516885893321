export default function (a, b) {
  const valueA = a instanceof Promise ? a.inputValue[0] : a[0];
  const valueB = b instanceof Promise ? b.inputValue[0] : b[0];
  if (valueA === 'provide' && valueB === 'provide') {
    return 0;
  } else if (valueA === 'provide') {
    return -1;
  } else if (valueB === 'provide') {
    return 1;
  }
  return 0;
}
